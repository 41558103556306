import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { SafeHtmlParser } from "./safe-html-parser";
import uuid4 from "uuid4";

const SectionLayout8 = ({ heading, button, button2, button3, items }) => {
  return (
    <section className={`my-5 my-md-6 my-xl-8`}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={7} className={`text-center`}>
            <h1 className="mb-0 intro-heading">{heading}</h1>
          </Col>
        </Row>
        {items && items.length > 0 && (
          <div className="mt-7">
            {items.map((item) => (
              <div className="mb-3 pb-1" key={uuid4()}>
                <h4 className="mb-3 fs-6 text-secondary">
                  {item.faqFields.faqFields.question}
                </h4>
                {item.faqFields.faqFields.answer && (
                  <div className="small-text">
                    <SafeHtmlParser
                      htmlContent={item.faqFields.faqFields.answer}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        <div className="d-flex flex-column flex-md-row justify-content-center mt-5">
          {button && button.url && (
            <Button
              className="px-4 py-3 mt-md-5 mb-2 me-md-3 w-fit daxPro-semibold"
              as={Link}
              to={button?.url}
              variant="secondary"
              target={button?.target ?? "_blank"}
            >
              {button?.title}
            </Button>
          )}
          {button2 && button2.url && (
            <Button
              className="px-4 py-3 mt-2 mt-md-5 mb-2 me-md-3 w-fit daxPro-semibold"
              as={Link}
              to={button2?.url}
              variant="secondary"
              target={button2?.target ?? "_blank"}
            >
              {button2?.title}
            </Button>
          )}
          {button3 && button3.url && (
            <Button
              className="px-4 py-3 mt-2 mt-md-5 mb-2 w-fit daxPro-semibold"
              as={Link}
              to={button3?.url}
              variant="secondary"
              target={button3?.target ?? "_blank"}
            >
              {button3?.title}
            </Button>
          )}
        </div>
      </Container>
    </section>
  );
};

export default SectionLayout8;
