import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { IoIosArrowForward } from "react-icons/io";
import { cutOffAtWord } from "../util/string";
import { SafeHtmlParser } from "./safe-html-parser";

const TestimonialCard = ({ title, image, content, imageAlt }) => {

  const cleanContent = (content, size) => {
    if (typeof window !== "undefined") {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/html");
      const paragraphs = doc.querySelectorAll("p");
      let cleanString = "";

      for (let i = 0; i < paragraphs.length && cleanString.length < size; i++) {
        const textContent = paragraphs[i].textContent || "";
        cleanString += textContent
          .replace(/&nbsp;/g, " ")
          .replace(/[\s\t]+/g, " ")
          .trim();
        if (cleanString.length < size) {
          cleanString += " ";
        }
      }

      if (cleanString.length < size) {
        const remainingContent = content
          .replace(/<\/?[^>]+(>|$)/g, "")
          .replace(/&nbsp;/g, " ")
          .replace(/[\s\t]+/g, " ");
        cleanString += remainingContent
          .substring(0, 160 - cleanString.length)
          .trim();
      }

      return `${cutOffAtWord(cleanString, size).trim()}`;
    } else {
      return "";
    }
  };

  return (
    <Col md={6} xl={4} className={`blog-card-wrapper p-3`}>
      <Card className="w-100 h-100 border p-4 rounded-0">
        {image && (
          <div className="blog-card-image position-relative mb-3">
            <Card.Img
              as={GatsbyImage}
              variant="top"
              image={image}
              alt={imageAlt}
              className="rounded-0"
            />
          </div>
        )}

        <Card.Body className="p-0 d-flex flex-column">
          {content && (
            <div className="mb-3">
              <SafeHtmlParser htmlContent={cleanContent(content, !image ? 950 : 300)} />
            </div>
          )}
          <h5
            className={`d-flex mt-3 mb-0 text-capitalize lh-base fs-6 mt-auto`}
          >
            {title}
          </h5>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default TestimonialCard;
