import React, { useEffect, useRef } from "react";
import "./contactForm.css";

const ContactForm = ({ title }) => {
	const formRef = useRef(null);

	useEffect(() => {
		const script = document.createElement("script");
		script.id = "formScript3551648000026426041";
		script.src =
			"https://crm.zoho.com/crm/WebFormServeServlet?rid=3655bf5871e06dd01d27dd5cddaee5b475d83dff1cd841f0da1597616e3523087c0371c5965779b608582612ca90ff8agidef6f52cb9eb7ce8e1c222e54d767ded42b0a9bf575d8cf57b56992d4d5b0fe0d&script=$sYG";
		script.async = true;
		script.defer = true;

		const handleScriptLoad = () => {
			const insertedForm = document.querySelector(
				'form[name="WebToLeads3551648000026426041"]'
			);
			if (
				insertedForm &&
				formRef.current &&
				!formRef.current.contains(insertedForm)
			) {
				formRef.current.appendChild(insertedForm);
			}

			// Remove any extraneous divs created by the script
			const extraDivs = document.querySelectorAll("#crmWebToEntityForm");
			extraDivs.forEach((div, index) => {
				if (index > 0) {
					div.remove();
				}
			});
		};

		script.onload = handleScriptLoad;
		document.body.appendChild(script);

		return () => {
			const existingForms = document.querySelectorAll(
				'form[name="WebToLeads3551648000026426041"]'
			);
			existingForms.forEach((form) => form.remove());

			if (script.parentNode) {
				script.parentNode.removeChild(script);
			}

			const extraDivs = document.querySelectorAll("#crmWebToEntityForm");
			extraDivs.forEach((div, index) => {
				if (index > 0) {
					div.remove();
				}
			});
		};
	}, []);

	return (
		<div>
			{title && <h3 className="mb-4">{title}</h3>}
			<div id="crmWebToEntityForm" className="zcwf_lblLeft" ref={formRef}>
				{/* The form will be injected here by the Zoho script */}
			</div>
		</div>
	);
};

export default ContactForm;
