import React from "react";
import { CookieBanner } from "@connorrjm/rjm-cookie-banner";
import Footer from "./landing-footer";
import Navigation from "./landing-navigation";
import { Helmet } from "react-helmet";

const LandingLayout = ({ children, isThankYouPage = false }) => {
  return (
    <>
      <Navigation isThankYouPage={isThankYouPage} />
      <CookieBanner url="/privacy-notice" afterLinkText="for Privacy Notice." />

      {children}
      <Footer />
    </>
  );
};

export default LandingLayout;
