import React, { useEffect, useRef } from "react";
import "./contactForm.css";

const ContactForm = ({ title }) => {
	const formRef = useRef(null);

	useEffect(() => {
		const script = document.createElement("script");
		script.id = "formScript3551648000063134001";
		script.src =
			"https://crm.zoho.com/crm/WebFormServeServlet?rid=9daa0757d95a8c7d574ae6fda22291bbce3b57bf928b0a7d27d9aa22be1a22ea6a4bfd7a40946d3a8f86bb649b3db885gid4563077cbcded768297238cd15d287cf5220d5e1c27c0a66554fd18aaca831bc&script=$sYG";
		script.async = true;
		script.defer = true;

		const handleScriptLoad = () => {
			const insertedForm = document.querySelector(
				'form[name="WebToLeads3551648000063134001"]'
			);
			if (
				insertedForm &&
				formRef.current &&
				!formRef.current.contains(insertedForm)
			) {
				formRef.current.appendChild(insertedForm);
			}

			// Remove any extraneous divs created by the script
			const extraDivs = document.querySelectorAll("#crmWebToEntityFormLanding");
			extraDivs.forEach((div, index) => {
				if (index > 0) {
					div.remove();
				}
			});
		};

		script.onload = handleScriptLoad;
		document.body.appendChild(script);

		return () => {
			const existingForms = document.querySelectorAll(
				'form[name="WebToLeads3551648000063134001"]'
			);
			existingForms.forEach((form) => form.remove());

			if (script.parentNode) {
				script.parentNode.removeChild(script);
			}

			const extraDivs = document.querySelectorAll("#crmWebToEntityFormLanding");
			extraDivs.forEach((div, index) => {
				if (index > 0) {
					div.remove();
				}
			});
		};
	}, []);

	return (
		<div>
			{title && <h3 className="mb-4">{title}</h3>}
			<div id="crmWebToEntityFormLanding" className="crmWebToEntityForm zcwf_lblLeft" ref={formRef}>
				{/* The form will be injected here by the Zoho script */}
			</div>
		</div>
	);
};

export default ContactForm;
